import { useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import initApp from '../../firebase/config';
import { useWindowSize } from '../../utils/utils';
import Me from '../Me/Me';
import MainPage from '../Principal/MainPage';
import Skills from '../Skills/Skills';
import Touch from '../Touch/Touch';
import WorkPage from '../Work/Work';
import './App.css';

function App() {

  const phoneScreen = useWindowSize() <= 700;

  initApp();

  const [page, setPage] = useState('');

  const meRef = useRef(null);
  const skillsRef = useRef(null);
  const workRef = useRef(null);
  const touchRef = useRef(null);


  const visibleHandler = (isVisible, component) => {
    if (isVisible) {
      setPage(component);
    }
  };

  const handleClick = (section) => {
    switch(section) {
      case 'me': 
        meRef.current.scrollIntoView();
        break;
      case 'skills': 
        skillsRef.current.scrollIntoView();
        break;
      case 'work': 
        workRef.current.scrollIntoView();
        break;
      case 'touch': 
        touchRef.current.scrollIntoView();
        break;
      default:
        break;
    }
  };

  return (
    <div className="App">
      {
        !phoneScreen && (
          <div className="social-media">
            <SocialMedia />
          </div>
        )
      }
      <div className="main-page">
        <MainPage />
      </div>
      <div className="sticky-thc">
        <Header page={page} handleClick={handleClick} />
      </div>
      <div ref={meRef}></div>
      <Me
          isVisibleHandler={(isVisibleOnScreen) => visibleHandler(isVisibleOnScreen, 'me')}
        />
      <div ref={skillsRef}>
        <Skills
          isVisibleHandler={(isVisibleOnScreen) => visibleHandler(isVisibleOnScreen, 'skills')}
        />
      </div>
      <div ref={workRef}></div>
      <WorkPage
        isVisibleHandler={(isVisibleOnScreen) => visibleHandler(isVisibleOnScreen, 'work')}
      />
      <div ref={touchRef}>
        <Touch
          isVisibleHandler={(isVisibleOnScreen) => visibleHandler(isVisibleOnScreen, 'touch')}
        />
      </div>
    </div>
  );
}

export default App;
