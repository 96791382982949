import { useEffect, useRef } from 'react';

import BarTabs from '../../components/BarTabs/BarTabs';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import VerticalTabs from '../../components/VerticalTabs/VerticalTabs';
import { useWindowSize } from '../../utils/utils';
import useOnScreen from '../../utils/visibleHook';

import './Work.css';

const WorkPage  = ({ isVisibleHandler }) => {

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    isVisibleHandler(isVisible);
  }, [isVisible]);
  
  const workExperience = [
    {
      name: "Wizeline",
      url: "https://www.wizeline.com/",
      role: "Software Engineer",
      date: "Dec 2020 - Present",
      goals: [
        "Develop React Web Applications with Redux and Hooks",
        "TechLead",
        "Implement Backstage from Spotify as a Developer Portal",
        "Use Docker and AWS EC2 to create and run a PostgresSQL database",
      ],
    }, {
      name: "Arkon Data",
      url: "https://www.arkondata.com/",
      role: "Front End Developer",
      date: "Mar 2020 to Dec 2020",
      goals: [
        "Develop a React Application with GraphQL and Apollo",
        "Use third party libraries to read and create BPMN flows"
      ],
    }, {
      name: "GFT",
      url: "https://www.gft.com/",
      role: "Front End Developer Sr.",
      date: "Sept 2018 to Mar 2020",
      goals: [
        "Architectural design for the creation of a mono repository for a Bank's applications using Nx",
        "Development the bank's mobile app in a hybrid way",
        "Implement monitoring tools",
        "Front End tech lead",
        "Mentor in a program to train new hires",
      ],
    }, {
      name: "BWR",
      url: "https://www.bwr.mx/",
      role: "Full Stack Developer Jr.",
      date: "Jan 2017 to Sept 2018",
      goals: [
        "Develop cross-platform Web and Mobile Apps participating from the requirements taking to the deployment",
        "Use GCP for mobile and web apps management",
        "Pre-sales work of monitoring tools from Broadcom and Oracle Cloud Platform",
        "Mentor in a program to train new hires",
        "Implement monitoring tools for mobile apps and servers",
      ],
    }, {
      name: "Habil",
      url: "https://www.habil.mx/",
      role: "Full Stack Developer Jr.",
      date: "July 2016 to Dec 2016",
      goals: [
        "Develop a mobile apps with Android Studio",
        "Creates services in NodeJS",
        "Use Amazon Web Services DynamoDB and Lambda",
      ],
    }
  ];

  const phoneScreen = useWindowSize() <= 1300;

  return (
    <div className="Work">
      <div ref={ref} className="Work-title">
        <HeaderTitle title="Work Experience" color="red" />
      </div>
      <div className="Work-container">
        {
          phoneScreen ? (
            <BarTabs items={workExperience} />
          ) : (
            <VerticalTabs className="Work-vertical-tabs" items={workExperience}/>
          )
        }
      </div>
    </div>
  );
}

export default WorkPage;
