import { useEffect } from 'react';
import { useWindowSize } from '../../utils/utils';
import './Header.css';

const Header  = ({ page, handleClick }) => {

  const phoneScreen = useWindowSize() <= 700;
  
  return (
      <ul className="Header">
        <div className="header-container">
          <li
            className="section"
            style={{ color: page === 'me' ? "white" : "#5b9bbc" }}
            onClick={() => handleClick('me')}
          >
            { phoneScreen ? "Me" : "About me" }
          </li>
          <li
            className="section"
            style={{ color: page === 'skills' ? "white" : "#5b9bbc" }}
            onClick={() => handleClick('skills')}
          >
            { phoneScreen ? "Ski" : "Skills" }
          </li>
          <li
            className="section"
            style={{ color: page === 'work' ? "white" : "#5b9bbc" }}
            onClick={() => handleClick('work')}
          >
            { phoneScreen ? "Exp" : "Experience" }
          </li>
          <li
            className="section"
            style={{ color: page === 'touch' ? "white" : "#5b9bbc" }}
            onClick={() => handleClick('touch')}
          >
            { phoneScreen ? "Con" : "Contact" }
          </li>
        </div>
      </ul>
  );
}

export default Header;
