import './HeaderTitle.css';

const HeaderTitle = ({ title, color, textColor = "black" }) => {
  return (
      <div className="HeaderTitle">
        <h2 className="header-title" style={{ color: textColor }}>{title}</h2>
        <div className="centered-line" style={{ backgroundColor: color }}></div>
      </div>
  );
}

export default HeaderTitle;
