import './SocialMedia.css';

import IconButton from '@mui/material/IconButton';
import GitHubIcon from '@mui/icons-material/GitHub';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const SocialMedia = () => {

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
      <div className="SocialMedia">
        <IconButton size="large" onClick={() => { handleClick("https://www.linkedin.com/in/ivan-martinez-vega/") }}>
          <div className="SocialMedia-icon">
            <LinkedInIcon fontSize="inherit" />
          </div>
        </IconButton>
        <IconButton size="large" onClick={() => { handleClick("https://github.com/") }}>
          <div className="SocialMedia-icon">
            <GitHubIcon fontSize="inherit" />
          </div>
        </IconButton>
        <IconButton size="large">
          <a
            href={`mailto:${"ivanvega08@gmail.com"}?subject=${encodeURIComponent("[Portfolio]") || ''}&body=${encodeURIComponent("Hi Ivan,") || ''}`}>
            <div className="SocialMedia-icon">
              <MailOutlinedIcon fontSize="inherit" />
            </div>
          </a>
        </IconButton>
        <IconButton size="large" onClick={() => { handleClick("https://twitter.com/ivan_auditore4") }}>
          <div className="SocialMedia-icon">
            <TwitterIcon fontSize="inherit" />
          </div>
        </IconButton>
      </div>
  );
}

export default SocialMedia;
