import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import './VerticalTabs.css';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, pt: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({ items }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {
          items.map((item, index) => {
            return (
              <Tab
                label={item.name}
                {...a11yProps(index)}
                key={`tab-${index}`}
                sx={{ minWidth: 150, maxWidth: 150 }}
              />
            )
          })
        }
      </Tabs>
      {
        items.map((item, index) => {
          return (
            <TabPanel value={value} index={index} key={`tab-panel-${index}`}>
              <div className="TabPanel-role">
                <h2 className="TabPanel-h2">{item.role}</h2>
                <a href={item.url} target="_blank" className="TabPanel-link">
                  <h2 className="TabPanel-company TabPanel-h2">{`@ ${item.name}`}</h2>
                </a>
              </div>
              <div className="TabPanel-date">
                {item.date}
              </div>
              <div className="TabPanel-goal-container">
                <ul>
                  {
                    item.goals.map(goal => {
                      return (
                        <li className="TabPanel-goal" key={`li-${goal}`}>{goal}</li>
                      )
                    })
                  }
                </ul>
              </div>
            </TabPanel>
          )
        })
      }
    </Box>
  );
}
