import { useEffect, useRef } from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import useOnScreen from '../../utils/visibleHook';

import './Touch.css';

const BootstrapButton = styled(Button)({
  fontSize: 18,
  padding: '20px 20px',
  border: '1px solid',
  lineHeight: 1.5,
  '&:hover': {
    backgroundColor: '#111b2e',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
});

const Touch = ({ isVisibleHandler }) => {

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    isVisibleHandler(isVisible);
  }, [isVisible]);

  return (
    <div className="Touch">
      <HeaderTitle title="Get in touch" color="red" textColor="white" />
      <div className="Touch-text">
        <p>
          I always looking for new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!
        </p>
      </div>
      <div className="Touch-div-button">
        <a
          className="Touch-button-link"
          href={`mailto:${"ivanvega08@gmail.com"}?subject=${encodeURIComponent("[Portfolio]") || ''}&body=${encodeURIComponent("Hi Ivan,") || ''}`}>
            <BootstrapButton variant="outlined" disableRipple>
              Say Hi!
            </BootstrapButton>
        </a>
      </div>
      <div ref={ref}></div>
      <p className="Touch-by">
        Designed and built by Ivan Martinez Vega
      </p>
    </div>
  );
}

export default Touch;
