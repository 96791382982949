import { useEffect, useRef } from 'react';

import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import profileImage from '../../images/profile.png';
import useOnScreen from '../../utils/visibleHook';

import './Me.css';

const Me = ({ isVisibleHandler }) => {

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    isVisibleHandler(isVisible);
  }, [isVisible,]);

  return (
    <div className="Me">
      <HeaderTitle title="About me" color="red" />
      <div className="Me-container">
        <div className="Me-body">
          <p className="Me-text" ref={ref}>
            I am a flexible person and enjoy having a good time while working on something that I am passionate about. I enjoy working in a great environment with people with which I can achieve mutual growth.
          </p>
          <h2 className="Me-subtitle">My Personal Interests</h2>
          <p className="Me-text">
            I love learning so when I'm not learning a programming language or experimenting with a framework. I enjoy spending my time doing any of the following:
          </p>
          <ul className="Me-text">
            <li>Playing <b>video games</b> of all consoles.</li>
            <li>Reading all kinds of books.</li>
            <li>Spend time with my <b>family</b> and my dog.</li>
            <li><b>Watching</b> movies and series.</li>
          </ul>
        </div>
        <div className="Me-image">
          <img className="Me-image-profile" src={profileImage} alt="profile" /> 
        </div>
      </div>
    </div>
  );
}

export default Me;
