import { useEffect, useRef } from 'react';

import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import useOnScreen from '../../utils/visibleHook';

import './Skills.css';

const Skills = ({ isVisibleHandler, sectionSelected }) => {

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    isVisibleHandler(isVisible);
  }, [isVisible]);

  const skills = [
    { Frameworks : ["React", "Angular 2+", "TypeScript", "Ionic 3", "Android", "Express"] },
    { Software : ["Docker", "Jenkins", "Git", "Nx", "Karma", "Jest", "MySQL", "PostgresSQL"] },
    { Cloud : ["AWS", "Firebase", "GCP", "Oracle OMC"] },
  ];

  return (
    <div className="Skills">
      <HeaderTitle title="Technical Skills" color="red" />
      <div className="Skills-main">
        <h4 className="Skills-h4">JAVASCRIPT</h4>
        <h4 className="Skills-h4">NODE.JS</h4>
        <h4 className="Skills-h4">GO</h4>
      </div>
      <h3 className="Skills-h3" >MOST FREQUENTLY USED TOOLS</h3>
      <div className="Skills-frameworks" ref={ref}>
        {
          skills.map(skill => {
            return (
              Object.entries(skill).map(entry => {
                const [key, value] = entry;
                return (
                  <div className="Skills-conatiner-tools" key={`div-${key}-${value}`}>
                    <h4 className="Skills-h4">{key.toLocaleUpperCase()}</h4>
                    <ul>
                      {
                        value.map(tool => {
                          return (<li key={`li-${value}-${tool}`}>{tool}</li>)
                        })
                      }
                    </ul>
                  </div>
                );
              })
            );
          })
        }
      </div>
    </div>
  );
}

export default Skills;
