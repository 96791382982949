import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB3_Dmj_OzwX-DpdCC0PcnI3Sm62PYIgg4",
  authDomain: "personal-page-e2131.firebaseapp.com",
  projectId: "personal-page-e2131",
  storageBucket: "personal-page-e2131.appspot.com",
  messagingSenderId: "916798477647",
  appId: "1:916798477647:web:c5301be0091fd46583a13a",
  measurementId: "G-7S2B58V6L9",
}

const initApp = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
};

export default initApp;
