import './MainPage.css';

const MainPage= () => {
  return (
    <div className="App">
      <header className="App-header">
        <p className="name">
          Ivan Martinez Vega
        </p>
        <div className="row">
          <div className="entered line"></div>
        </div>
        <p className="title">
          Software Engineer
        </p>
      </header>
    </div>
  );
}

export default MainPage;
